import { useCallback, useContext, useEffect } from 'react';
import Link from 'components/atoms/Link';

import { BodyText, BodyTextAlt, H1, H2 } from 'components/atoms/Typography';
import Button from 'components/atoms/Button';
import {
    FacebookIcon,
    InstagramIcon,
    LogoIcon,
    LogoMobileIcon,
    PinterestIcon,
} from 'components/atoms/Icons';
import SiteContext from 'context/SiteContext';

const Footer = () => {
    const {
        store: { settings },
    } = useContext(SiteContext);

    const handleToggleScrollLock = lock => {
        if (lock) {
            const styleSheet = document.createElement('style');
            styleSheet.setAttribute('id', 'termlyPrefBody');
            styleSheet.innerText = `body{position:relative !important;margin-top:0 !important;overflow: hidden;}`;
            document.head.appendChild(styleSheet);

            return;
        }

        document.getElementById('termlyPrefBody')?.remove();
    };

    const { footer, phone, email, tradeEmail, pinterest, facebook, instagram } = { ...settings };
    const handleConsentPreferencesClick = event => {
        event.preventDefault();

        handleToggleScrollLock(true);

        window.displayPreferenceModal();
        return false;
    };

    const attachEventListener = useCallback(() => {
        const closePreferencesSelectors = [
            '#termly-code-snippet-support .t-closeModal',
            '#termly-code-snippet-support .t-declineAllButton',
            '#termly-code-snippet-support .t-allowAllButton',
        ];
        const openPreferencesSelectors = [
            '#termly-code-snippet-support .t-preferencesLink',
            '#termly-code-snippet-support .t-preference-button',
        ];

        // Unlock scroll
        const closePreferencesHandler = event => {
            let target = event.target;
            if (!target) {
                // event.target is null
                return;
            }

            if (!target.matches(closePreferencesSelectors)) {
                target = target.closest(closePreferencesSelectors);
            }

            if (!target) {
                // target.closest is null
                return;
            }

            if (target.matches(closePreferencesSelectors)) {
                handleToggleScrollLock(false);
            }
        };

        // Lock scroll
        const openPreferencesHandler = event => {
            let target = event.target;
            if (!target) {
                // event.target is null
                return;
            }

            if (!target.matches(openPreferencesSelectors)) {
                target = target.closest(openPreferencesSelectors);
            }

            if (!target) {
                // target.closest is null
                return;
            }

            if (target.matches(openPreferencesSelectors)) {
                handleToggleScrollLock(true);
            }
        };

        document.addEventListener('click', closePreferencesHandler);
        document.addEventListener('click', openPreferencesHandler);

        return () => {
            document.removeEventListener('click', closePreferencesHandler);
            document.removeEventListener('click', openPreferencesHandler);
        };
    }, []);

    useEffect(() => {
        attachEventListener();
    }, []);

    return settings?.footer ? (
        <footer className="footer-main mt-12 lg:mt-24">
            <div className="container pt-m-md pb-m-lg md:pt-10 md:pb-16 grid grid-cols-6 md:grid-cols-12 gap-5 md:gap-40px">
                <div className="col-span-2 md:col-span-3 xxl:col-span-4">
                    <Link href="/">
                        <LogoIcon className="hidden md:block" fill="currentColor" />
                        <LogoMobileIcon className="block md:hidden" fill="currentColor" />
                    </Link>
                </div>
                <div className="order-3 md:order-none col-start-3 col-span-4 md:col-span-9 xxl:col-span-8 mt-14 md:mt-0">
                    {footer?.links?.length > 0 && (
                        <ul className="footer-links footer-links-main md:flex flex-wrap mb-5">
                            {footer?.links?.map((link, index) => (
                                <li className="mb-xs md:mr-3" key={index}>
                                    {link?.onClick ? (
                                        <BodyText
                                            as="button"
                                            color="#fff"
                                            onClick={event => {
                                                link.onClick === 'handleConsentPreferencesClick' &&
                                                    handleConsentPreferencesClick(event);
                                            }}
                                        >
                                            {link.title}
                                        </BodyText>
                                    ) : (
                                        <Link href={link.url} key={index}>
                                            <BodyText as="span" color="#fff">
                                                {link.title}
                                            </BodyText>
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="hidden md:flex">
                        <Link href="/trade-program">
                            <Button
                                className="w-full lg:w-72"
                                as="span"
                                inverted
                                label="Signup for a trade account"
                            />
                        </Link>
                    </div>
                </div>
                <div className="col-span-full order-3 mt-10 md:hidden">
                    <Link href="/trade-program">
                        <Button as="span" inverted label="Signup for a trade account" />
                    </Link>
                </div>
                {footer.show_contact_info && (
                    <div className="order-2 md:order-none col-start-3 md:col-start-auto col-span-4 xl:col-span-5 md:mt-40 self-end">
                        {phone && (
                            <div className="phone">
                                <BodyTextAlt className="mb-0.5" color="#fff" opacity="0.4">
                                    Phone
                                </BodyTextAlt>
                                <BodyText
                                    as="a"
                                    href={`tel:${phone}`}
                                    className="inline-block mb-8"
                                    color="#fff"
                                >
                                    {phone}, Ext. #1 General, Ext. #2 Trade
                                </BodyText>
                            </div>
                        )}
                        {(email || tradeEmail) && (
                            <div className="email">
                                <BodyTextAlt className="mb-0.5" color="#fff" opacity="0.4">
                                    Email
                                </BodyTextAlt>
                                {email && (
                                    <BodyText
                                        as="a"
                                        href={`mailto:${email}`}
                                        className="inline-block"
                                        color="#fff"
                                    >
                                        {email}
                                    </BodyText>
                                )}
                                {tradeEmail && (
                                    <BodyText
                                        as="a"
                                        href={`mailto:${tradeEmail}`}
                                        className="block"
                                        color="#fff"
                                    >
                                        {tradeEmail}
                                    </BodyText>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="order-last md:order-none col-span-4 md:col-span-5 xl:col-span-4 mt-9 md:mt-40 self-end">
                    <ul className="footer-links footer-links-social flex flex-wrap items-center -m-1 sm:-mx-1.5">
                        {footer.show_social_media && (
                            <>
                                {pinterest && (
                                    <li>
                                        <a
                                            href={pinterest}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="block p-5 rounded-full m-1 sm:mx-1.5 border border-white"
                                        >
                                            <PinterestIcon />
                                        </a>
                                    </li>
                                )}
                                {facebook && (
                                    <li>
                                        <a
                                            href={facebook}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="block p-5 rounded-full m-1 sm:mx-1.5 border border-white"
                                        >
                                            <FacebookIcon />
                                        </a>
                                    </li>
                                )}
                                {instagram && (
                                    <li>
                                        <a
                                            href={instagram}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="block p-5 rounded-full m-1 sm:mx-1.5 border border-white"
                                        >
                                            <InstagramIcon />
                                        </a>
                                    </li>
                                )}
                            </>
                        )}
                        <li className="w-full md:w-auto">
                            <Link href="/blog" className="block m-1 sm:mx-1.5">
                                <H2 as="span" color="#fff">
                                    Stories
                                </H2>
                            </Link>
                        </li>
                        <li className="w-full md:hidden">
                            <small className="block m-1 sm:mx-1.5">
                                <button
                                    className="opacity-50"
                                    onClick={handleConsentPreferencesClick}
                                    id="termly-consent-preferences"
                                >
                                    Consent Preferences
                                </button>
                            </small>
                        </li>
                    </ul>
                </div>
                <BodyText
                    as="div"
                    className="order-4 md:order-none copyright whitespace-nowrap col-span-2 md:col-span-3 md:text-right transform translate-y-10 md:translate-y-0 origin-top-left -rotate-90 md:rotate-0 md:mt-40 self-end"
                    color="#fff"
                >
                    <small className="hidden md:block mb-2">
                        <button
                            className="opacity-50"
                            onClick={handleConsentPreferencesClick}
                            id="termly-consent-preferences"
                        >
                            Consent Preferences
                        </button>
                    </small>
                    <span className="block">
                        ©ZIA · All Rights <br className="block lg:hidden" />
                        Reserved
                    </span>
                </BodyText>
            </div>
        </footer>
    ) : null;
};

export default Footer;
